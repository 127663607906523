<template>
  <div>
    <ListingLayout
      :sub-screen="2"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :bread-crumbs="false"
      no-filters
      vertical-scroll
      @filterOption="onFiltersChange"
    >
      <template v-slot:chasis_no="{ item }">
        <td class="text-center">
          <div
            v-if="$admin.hasAccessTo('single-vehicle-trips.list')"
            class="imei-column"
            @click="goToListingPage(item)"
          >
            {{ item.chasis_no }}
          </div>
          <div v-else>
            {{ item.chasis_no }}
          </div>
        </td>
      </template>

      <template v-slot:device_time="{ item }">
        <div>
          {{ item.device_time | formatDate }}
        </div>
      </template>
      <template v-slot:timestamp="{ item }">
        <div>
          {{ item.timestamp | formatDate }}
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <!-- <v-card>
          <v-text-field
            v-model="options.search"
            dense
            clearable
            :label="$t('select')"
            @change="fetchData"
          />
        </v-card> -->
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
          flat
          class="action-toolbar"
        > -->

        <!-- <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        /> -->

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    // refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleTrips/getIsLoading",
      headers: "vehicleTrips/getTripTelematicsHeaders",

      meta: "vehicleTrips/getTripTelematicsMeta",
      list: "vehicleTrips/getTripTelematicsList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      //   {
      //       text: this.$t("id"),
      //       align: "center",
      //       sortable: true,
      //       value: "id",
      //     },
      let arr = [];
      this.headers.forEach((el) => {
        arr.push({
          text: el.heading,
          value: el.key,
          sortable: false,
          align: "center",
        });
      });

      return arr;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  async mounted() {
    await this.fetchHeaders();
  },
  methods: {
    async fetchHeaders() {
      const params = {};
      params.tenant_id = this.$route?.query?.tID || this.authUser.tenant_id;
      params.vehicle_id = this.$route?.query?.vehicle_id;
      await this.$store.dispatch("vehicleTrips/tripTelematicsHeaders", params);
    },

    async fetchData() {
      const params = { ...this.options };
      params.trip_interval_id = this.$route.params?.id;
      params.imei = this.$route?.query?.imei;
      await this.$store
        .dispatch("vehicleTrips/tripTelematicsList", params)
        .then(() => {
          if (this.options.page > (this.meta?.lastPage || 1)) {
            this.options.page = 1;
          }
        });
    },
  },
};
</script>
